<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="Apply For Short Leave Exception"
      slot="title"
      link="/helpContent/addShortLeaveException"
    />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-2">
          <!-- <inputDate label="Date" v-model="form.date" rules="required" /> -->
                     <!-- sagar 05-09-2023 changes next date disable -->
                     <InputDate label="Date"   rules="required" :options="options" v-model="form.date" />
        </div>
        <div class="col-sm-1">
          <InputTime label="Time" v-model="form.time" rules="required" />
        </div>
        <div class="col-sm-2">
          <FormSelect
            label="Reason Category"
            :items="reasonList"
            item-name="reason"
            item-value="ID"
            v-model="form.reasonId"
            rules="required"
          />
        </div>
        <div class="col-sm-4">
          <InputTextArea
            label="Reason"
            v-model="form.reason"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <InputTextArea
            label="Evidence"
            v-model="form.evidence"
            rules="required"
          />
        </div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>

    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
      <FormButton
        
        @click="()=>{$router.go(-1)}"
          >Ok</FormButton
        ></div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { addSLException, leaveReason,getSlExceptionById } from "../api";
import InputDate from "Components/form/InputDate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
export default {
  name: "add",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    InputDate,
    FormWrapper,
    PageTitle,
    ValidationObserver,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      form: {
        date: "",
        time: "",
        reason: "",
        evidence: "",
        reasonId: null,
        id:0
      },
      reasonList: [],
      options: {
disabledDate(date) {
  return date && date.valueOf() > Date.now();
  }
},
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},

  created() {
    this.getLeaveRecord();
  },
  methods: {
    sendData() {
      this.showMessage.isVisible = false;
      this.infoMessage.isVisible = false;
      this.$refs.validator.validate().then((result) => {
        if (result) {
          const data = {
            date: this.form.date,
            time: this.form.time,
            evidence: this.form.evidence,
            reason: this.form.reason,
            reasonId: this.form.reasonId,
          };
          addSLException(data).then((res) => {
            if (res.data[0].status > 1) {
              this.infoMessage.isVisible = true;
              this.infoMessage.message = res.data[0].message;
            } else {
              this.form = {};
              this.showMessage.isVisible = true;
              this.showMessage.message = res.data[0].message;
            }
          });
        }
      });
    },
    getLeaveRecord() {
      leaveReason().then((res) => {
        this.reasonList = res.data;
      });
      if (this.$route.params.id) {
        const data={
id:parseInt(atob(this.$route.params.id))
        }
        getSlExceptionById(data).then((res) => {
           this.form.date=res.data[0].leaveDate;
           this.form.time=res.data[0].startTime;
           this.form.evidence=res.data[0].Evidence;
            this.form.reason=res.data[0].Notes;
           this.form.reasonId=res.data[0].LeaveReason_id;
           this.form.id=parseInt(atob(this.$route.params.id));
      });
      }
    },
  },
};
</script>
